/* eslint-disable no-console,no-undef, camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ButtonHris, HrisCard, Time, TimeIcon, Wrapper} from './style';
import { ImageUrl } from '../../utils/constants';
import { getLastSyncDetails } from "../../utils/methods";

class HrisCardComponent extends Component {
  constructor() {
    super();
    this.state = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      isExpired: false,
    };
  }

  componentDidMount() {
    const {data}= this.props;
    this.interval = setInterval(() => this.updateCountdown(data.next_sync_date), 1000);
    this.updateCountdown(data.next_sync_date);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  updateCountdown(date) {
    const targetDate = new Date(date);
    const now = new Date();
    const timeDifference = targetDate - now;

    if (timeDifference < 0) {
      this.setState({ isExpired: true });
      clearInterval(this.interval);
      return;
    }

    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

    this.setState({
      days,
      hours,
      minutes,
      seconds,
    });
  }

  render() {
    const {  data, showSyncPopup } = this.props
    const {days, hours, minutes, seconds} = this.state
    return (
      <HrisCard >
        <div className='wrapper'>
          <div className='image'>
            <img src={data.icon}/>
          </div>
          <div className='details'>
            <div className='lastsync'>Last sync: {getLastSyncDetails(data?.last_sync_date)}</div>
            <div className='nextsync'>Next sync countdown</div>
            <Wrapper>
              <Time>
                <div className='timeMain'>
                  <div className='timeCard'>{days?days:"00"}</div>
                  <div className='days'>Days</div>
                </div>
                <TimeIcon>
                  <img src={ImageUrl+"/Hris-image/time.svg"}/>
                </TimeIcon>
                <div className='timeMain'>
                  <div className='timeCard'>{hours?hours:"00"}</div>
                  <div className='days'>Hours</div>
                </div>
                <TimeIcon>
                  <img src={ImageUrl+"/Hris-image/time.svg"}/>
                </TimeIcon>
                <div className='timeMain'>
                  <div className='timeCard'>{minutes?minutes:"00"}</div>
                  <div className='days'>Min</div>
                </div>
                <TimeIcon>
                  <img src={ImageUrl+"/Hris-image/time.svg"}/>
                </TimeIcon>
                <div className='timeMain'>
                  <div className='timeCard'>{seconds?seconds:"00"}</div>
                  <div className='days'>Sec</div>
                </div>
              </Time>
            </Wrapper>
          </div>
        </div>
        <ButtonHris disable={data.next_sync_date && minutes} onClick={ data.next_sync_date && minutes? null: ()=>showSyncPopup()}>
          {data.next_sync_date?"Edit Sync":"Schedule Sync"}
        </ButtonHris>
      </HrisCard>
    )
  }
}

HrisCardComponent.propTypes = {
  showSyncPopup: PropTypes.func,
  data: PropTypes.array
};

export default connect(null, null)(HrisCardComponent);
