import styled from 'styled-components';
import {Modal} from "react-bootstrap";

const StyledModal = styled(Modal)`
  .modal-dialog {
    width: 660px;
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    transform: translate(0, 50vh) translate(0, -50%) !important;
    margin: 0 auto;
    .modal-content {
      padding: 25px;
      background-color: ${({bgcolor}) => (bgcolor || !bgcolor) ? 'transparent' : '#fff'};
    }
    .close {
      font-size: 27px;
      background: #C4C4C4;
      width: 26px;
      height: 26px;
      outline: none;
      border: none;
    }
    @media (max-height: 530px) {
      transform: none !important;
      margin: 30px auto;
    }
    @media (max-width: 766px) {
      width: 95%;
    }
    @media (min-width: 576px){
      max-width:none;
    }
  }
`;

const StyledHeader = styled(Modal.Header)`
  padding: 0px 0 7px 0;
  border-bottom: none;
  display:block;
  text-align: center;
  justify-content: center !important;
  padding-top: 0px !important;
  border-bottom: none !important;
  > span {
    font-family: 'Rubik-Medium';
    font-size: 24px;
    line-height: 24px;
    color: #0D4270;
    text-decoration: underline;
    text-transform: capitalize;
    padding-left: 0px;
  }
`;
const StyledBody = styled(Modal.Body)`
height: 100%;
width: 100%;
display: flex;
justify-content: center;
flex-flow: column;
align-items: center;
padding: 15px 15px 0px 15px;
`;
const ContentContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 18px;
  border-radius: 6px;
  font-family: Rubik;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  color: #0D4270;
  flex-flow: column;
  @media (max-width: 400px) {
    padding: 10px;
  }
  
  > div {
    text-align: center;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  text-align: center;
`;
const TipsButton = styled.button`
  width: 65%;
  height: 50px;
  outline: none;
  border: none;
  background: linear-gradient(90deg, #006FBC -1.11%, #7F43FF 97.46%);
  border-radius: 6px;
  > span {
    color: #FFFFFF;
    font-family: 'Rubik-Medium';
    text-transform: capitalize;
    font-size: 18px;
  }
`;
const ImageContainer = styled.div`
width: 100%;
height: 100%;
display: flex;
justify-content: center;
align-items: center;
margin-top: 20px;
margin-bottom: 20px;
> img {
  width: 190px;
}
`;
export {
  StyledModal, StyledHeader, StyledBody, ContentContainer, ButtonContainer, TipsButton, ImageContainer
}