import React from 'react';
import PropTypes from 'prop-types';
import { StyledModal, StyledHeader, StyledBody, ImageContainer, ContentContainer, TipsButton, ButtonContainer } from './styles';

class HraPopUp extends React.Component {
  render() {
    const { showModal, history, onHide } = this.props;
    return (
      <StyledModal
        show={showModal}
        onHide={() => onHide()}
      >
        <div onClick={onHide} style={{ "display": "flex", "justifyContent": "flex-end" , "cursor":"pointer"}}>
          <img src="/public/images/NewDashboardV2/crossIconNew.png" width="20px" alt="" />
        </div>
        <StyledHeader >
          <span>Health Risk Assessment</span>
        </StyledHeader>
        <StyledBody>
          <ImageContainer>
            <img src="/public/images/NewDashboardV2/hra.png" />
          </ImageContainer>
          <ContentContainer>
            <div>To Get Your Daily Tips, Complete your Health Risk</div>
            <div>Assessment ?</div>
          </ContentContainer>
          <ButtonContainer>
            <TipsButton onClick={() => history.push('/wellness-assessment')}>
              <span>Start Assessment</span>
            </TipsButton>
          </ButtonContainer>
        </StyledBody>
      </StyledModal>
    );
  }
}
HraPopUp.propTypes = {
  showModal: PropTypes.bool,
  onHide: PropTypes.func,
  history: PropTypes.object
};
export default HraPopUp;