import React from 'react';
import PropTypes from 'prop-types';
import Loading from '../Loading';
import {Img} from "react-image";

const ImageThumbnail = ({src, alt, onClick, ...rest}) => (
  <Img
    src={src}
    alt={alt}
    onClick={onClick}
    loader={<Loading />}
    unloader={
      <Img
        src='/public/images/placeholder/video-placeholder.jpg'
        alt={alt}
        onClick={onClick}
        {...rest}
      />
    }
    {...rest}
  />
);

ImageThumbnail.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string.isRequired,
  onClick: PropTypes.func
};

export default ImageThumbnail;
