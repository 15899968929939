import styled  from 'styled-components';
import { Modal } from 'react-bootstrap';

const Layout = styled.div`
  width: 100%;
  padding: 25px;
  .main{
    width: 100%;
    max-width: 1246px;
    margin: auto;
  }
`;

const BuddyTab = styled.div`
display: flex;
min-width: ${({width}) => width ? "auto" : '114px'};
height: 40px;
padding: ${({padding}) => padding ? "10px 15px" : ''};
background: ${({active}) => active ? '#007AB1' : 'white'};
border-radius: 6px;
font-family: ${({active}) => active ? 'Rubik-Medium' : 'Rubik'};
font-size: 16px;
font-weight: 500;
line-height: 20px;
color: ${({active}) => active ? '#FFFFFF' : '#007AB1'};
margin: ${({margin}) => margin ? margin : 'auto auto auto 12px'};
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
justify-content: center;
align-items: center;
cursor: pointer;
:hover{
  background:#005C87;
  color:#ffff;
}
`;

const Header = styled.div`
display:flex;
width:100%;
padding: 8px;
background:white;
border-radius:4px;
.add{
    width:40px;
    height:40px;
    display:flex;
    justify-content:center;
    align-items:center;
    background: #007AB10D;
    cursor:pointer;
    border-radius:6px;
}
`;

const NoData = styled.div`
display:flex;
width:100%;
flex-wrap:wrap;
margin-top: ${({margin}) => margin ? margin : '0'};
.image{
    width: 388px;
    height: 250px;
    display:flex;
    margin:auto;
    >img{
        width:100%;
        height:100%;
    }
}
.text{
    width:100%;
    display:flex;
    font-family: Rubik;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color:#005c87;
    text-align:center;
    justify-content:center;
    margin-top:40px;
}
`;

const AddUserCard = styled.div`
position:relative;
width: 300px;
display:flex;
margin: auto 0px auto auto;
flex-wrap: wrap;
left: 7px;
bottom: 5px;
.hand{
    width: 100%;
    display:flex;
    .image{
        height: 48px;
        width: auto;
        display:flex;
        margin: auto 8px auto auto;
        >img{
            width:100%;
            height:100%;
        }        
    }
}
.adduser{
width: 300px;
border-radius: 6px;
border: 1px solid #005C87;
box-shadow: 0px 13px 28px 0px #0000004D;
background:white;
padding:12px;
.heading{
    font-family: Rubik-Medium;
    font-size: 16px;
    font-weight: 500;
    line-height: 18.96px;
    text-align: left;
    color: #005c87;
    padding-bottom: 12px;
  }
.subtext{
    font-family: Rubik;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #005c87;
    border-bottom:1px solid #005C874D;
    padding-bottom: 12px;
  }
.skip{
    width:100%;
    display:flex;
    justify-content:flex-end;
    font-family: Rubik;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: #007AB1;
    padding-top:12px;
    cursor:pointer;
  }
}
`;

const HeaderTitle = styled.div`
width:100%;
display:flex;
margin: 32px 0;
flex-wrap: nowrap;
align-item:center;
.text{
    font-family: Rubik-Medium;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #005c87;
    display:flex;
    align-item:center;
}
.line{
    background: #005C874D;
    display:flex;
    width:auto;
    align-items:center;
    height:1px;
    width: calc(100% - 105px);
    display:flex;
    align-items:center;
    margin:auto 0px auto 12px;
}
`;

const ButtonHris = styled.div`
    width: ${({width}) => width ? width : '100%'};
    height: 40px;
    display:flex;
    background: ${({disable}) => disable ? "#007AB14D" : '#007AB1'};
    cursor: ${({disable}) => disable ? "not-allowed" : 'pointer'};
    border-radius:6px;
    font-family: Rubik-Medium;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: white;
    justify-content: center;
    align-items: center;
`;

const HrisCard = styled.div`
width: 400px;
border-radius: 6px;
background:white;
padding: 12px;
display:flex;
flex-wrap: wrap;
margin-right: 15px;
.wrapper{
    width:100%;
    display:flex;
    margin-bottom: 16px;
    .image{
        width: 140px;
        height:140px;
        border: 1px solid #005C871A;
        border-radius:6px;
        >img{
            width: auto;
            height: 100%;
            max-width: 120px;
        }
    }
    .details{
        padding: 0 16px 0px 20px;
        display:flex;
        flex-wrap:wrap;
        .lastsync{
            font-family: Rubik;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            text-align: left;
            color: #005C87;
            padding-bottom:12px;
            width:100%;
            align-items: center;
            display: flex;
        }
        .nextsync{
            font-family: Rubik;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            text-align: left;
            color: #005C87;
            padding-bottom:8px;
            width:100%;
            align-items: center;
            display: flex;
        }

    }
}
`;

const Time = styled.div`
  width:100%;
  display:flex;
  .timeMain{
   display:block;
  .timeCard{
    width: 43px;
    height: 40px;
    border-radius: 6px;
    display:flex;
    align-items:center;
    justify-content:center;
    font-family: Rubik;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: #005c87;
    margin-bottom: 5px;
    border: 1px solid var(--navy-30, #005C874D)
  }
  .days{
    font-family: Rubik;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
    color:#005C8799;
    justify-content:center;
  }
}
`;

const TimeIcon = styled.div`
height: 40px;
display:flex;
width: 20px;
align-items:center;
justify-content: center;
`;

const Wrapper = styled.div`
width:100%;
display:flex;
flex-wrap:wrap;
`;

const StyledModal = styled(Modal)`
.header{
    width:100%;
    padding: 16px;
    display:flex;
    justify-content:space-between;
    align-items:center;
    border-bottom: 1px solid #005C874D;
    .text{
      font-family: Rubik-Medium;
      font-size: 18px;
      line-height: 24px;
      text-align: left;
      color: #005c87;
      align-items:center;
      display:flex;
    }
    .iconCross{
      width:24px;
      height:24px;
      cursor:pointer;
      >img{
        width:100%;
        height:100%;
      }
    }
}
.body{
  display:flex;
  width:100%;
  padding: 16px;
  flex-wrap: wrap;
}
.buttonWrapper{
  padding: 8px;
  width:100%;
  display:flex;
}
@media (min-width: 576px){
  .modal-dialog {
    max-width:100% !important;
  }}
  .modal-dialog {
    transform: ${({transform}) => transform ? 'translate(0, 15vh) translate(0, -15%) !important' : 'translate(0, 50vh) translate(0, -50%) !important'};
    margin: ${({change}) => change ? '150px auto' : '0 auto'};
    // box-shadow: 0 5px 15px rgba(0,0,0,.5);
    @media (min-width: 576px){
      .modal-dialog {
        max-width:100% !important;
      }}
    .modal-content{
      border:none;
      box-shadow: 0 5px 15px rgba(0,0,0,.5);
    }
    @media (max-height: 460px) {
      transform: none !important;
      margin: 30px auto;
    }
    width: ${({width}) => width ? width : '490px'};
    height: ${({change, height}) => height? height :change ? '573px' : '473px'};
    @media (max-width: 500px){
      width: 95%;
      height: auto;
    }
  }

  .modal-body{
    padding: ${({padding}) => padding ? padding : '25px 0 25px 0'};
  }
  
  .modal-container {
   width: 100%;
   display: block;
   border: none;
  }
`;

const PopupLine = styled.div`
width:100%;
display:flex;
flex-wrap:wrap;
align-items:center;
margin-bottom:16px;
.checkmark{
  width: 24px;
  height: 24px;
  border-radius:50%;
  display:flex;
  justify-content:center;
  align-items:center;
  border: 1px solid #007AB1;
  cursor:pointer;
  >img{
    width: 16px;
    height:16px;
  }
}
.text{
  font-family: Rubik;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #005c87;
  align-items:center;
  display:flex;
  padding-left: 8px;
}
`;

export { Layout, Header, BuddyTab, NoData, AddUserCard, HeaderTitle, ButtonHris, HrisCard, Time, TimeIcon, Wrapper, StyledModal, PopupLine };