import React from 'react';
import PropTypes from 'prop-types';
import { ImageUrl } from '../../utils/constants';
import {Img} from 'react-image'

const Image = ({image, ...props}) => (
  <Img 
    src={image ? `${ImageUrl}/${image}` : '/public/images/neutral_avatar.svg'}
    unloader={
      <Img
        src='/public/images/neutral_avatar.svg'
        alt='avatar'
      />
    }
    {...props}
  />
);

Image.propTypes = {
  image: PropTypes.any
};

export default Image;
